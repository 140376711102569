.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.img-list {
  display: flex;
  justify-content: center;
}
.img-item {
  width: 20%;
  margin: 20px;
}
.img-item img {
  width: 100%;
}
.bottom-info {
  display: flex;
  justify-content: center;
  align-items: center;
}
.text-color {
  color:#bbb;
  margin: 20px;
}